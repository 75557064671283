.MapSearch {
    position: absolute;
    top: 0.75rem;
    right: 3.5rem;
    width: 11.75em;
    padding: 1rem;
    background-color: white;
  
    .md-form {
      width: 7.75rem;
    }
  
    .search {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
    }
  
    input.error { 
      color: red;
    }
  }
  