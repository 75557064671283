@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content_active {
  transform: translate3d(0, 4px, 0);
  transition-duration: 500ms;
}

.modal {
  max-height: calc(100vh - 4rem);
}

.nitrateAppForm {
  max-height: calc(100vh - 340px);
}

.innerHtml blockquote,
.innerHtml dl,
.innerHtml dd,
.innerHtml h1,
.innerHtml h2,
.innerHtml h3,
.innerHtml h4,
.innerHtml h5,
.innerHtml h6,
.innerHtml hr,
.innerHtml figure,
.innerHtml p,
.innerHtml pre {
  margin: revert !important;
  font-size: revert !important;
  font-weight: revert !important;
}

.innerHtml ol,
.innerHtml ul,
.innerHtml menu {
  list-style: revert !important;
  margin: revert !important;
  padding: revert !important;
}

.innerHtml a {
  color: revert !important;
  text-decoration: revert !important;
}

@layer components {
  /* .my-react-select-container {
  } */
  .reactSelectContainer.reactSelect--is-disabled > .reactSelect__control {
    @apply bg-stone-200 dark:bg-neutral-700;
  }

  /* .reactSelectContainer .reactSelect__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  } */

  .reactSelectContainer .reactSelect__menu {
    @apply dark:bg-neutral-700;
  }

  .reactSelectContainer .reactSelect__option {
    @apply dark:text-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
  }

  /* .reactSelectContainer .reactSelect__indicator-separator {
    @apply bg-neutral-400;
  } */

  .reactSelectContainer .reactSelect__input-container,
  .reactSelectContainer .reactSelect__placeholder,
  .reactSelectContainer .reactSelect__single-value {
    @apply dark:text-neutral-200;
  }
}

.smartbanner-show.smartbanner-margin-top {
  margin-top: 80px;
}

.smartbanner-show.smartbanner-margin-bottom {
  margin-bottom: 80px;
}

.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: Helvetica Neue, sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-family: ArialRoundedMTBold, Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-icon,
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  white-space: normal;
  width: calc(99% - 201px);
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 700;
}

.smartbanner-wrapper {
  max-width: 110px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-author:empty + .smartbanner-description {
  max-height: 50px;
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-ios {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
}

.smartbanner-ios .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-family: Arial;
  color: #888;
  text-shadow: 0 1px 0 #fff;
  -webkit-font-smoothing: none;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
  color: #888;
}

.smartbanner-ios .smartbanner-icon {
  background-size: cover;
}

.smartbanner-ios .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
  font-weight: 300;
}

.smartbanner-ios .smartbanner-title {
  color: #4d4d4d;
  font-weight: 500;
}

.smartbanner-ios .smartbanner-button {
  padding: 0 10px;
  font-size: 15px;
  min-width: 10%;
  font-weight: 400;
  color: #0c71fd;
}

.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover {
  background: #f2f2f2;
}

.smartbanner-android {
  background: #3d3d3d
    url("data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7");
  box-shadow: inset 0 4px 0 #88b131;
  line-height: 82px;
}

.smartbanner-android .smartbanner-close {
  border: 0;
  max-width: 17px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  text-decoration: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.8),
    0 1px 1px hsla(0, 0%, 100%, 0.3);
  cursor: pointer;
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: #eee;
}

.smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}

.smartbanner-android .smartbanner-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
}

.smartbanner-android .smartbanner-title {
  color: #fff;
  font-weight: 700;
}

.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #d1d1d1;
  font-weight: 700;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  background: none;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: #42b6c9;
  background: linear-gradient(180deg, #42b6c9, #39a9bb);
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}

.smartbanner-android .smartbanner-button-text:active,
.smartbanner-android .smartbanner-button-text:hover {
  background: #2ac7e1;
}

.smartbanner-kindle,
.smartbanner-windows {
  background: #f4f4f4;
  background: linear-gradient(180deg, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-kindle .smartbanner-close,
.smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 #fff;
}

.smartbanner-kindle .smartbanner-close:active,
.smartbanner-kindle .smartbanner-close:hover,
.smartbanner-windows .smartbanner-close:active,
.smartbanner-windows .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-kindle .smartbanner-icon,
.smartbanner-windows .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-kindle .smartbanner-info,
.smartbanner-windows .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-kindle .smartbanner-title,
.smartbanner-windows .smartbanner-title {
  color: #4d4d4d;
  font-weight: 700;
}

.smartbanner-kindle .smartbanner-button,
.smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(180deg, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 hsla(0, 0%, 100%, 0.6),
    inset 0 2px 0 hsla(0, 0%, 100%, 0.7);
}

.smartbanner-kindle .smartbanner-button:active,
.smartbanner-kindle .smartbanner-button:hover,
.smartbanner-windows .smartbanner-button:active,
.smartbanner-windows .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(180deg, #dcdcdc, #efefef);
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

/* Hide the flag container */
.tw-phone-input .PhoneInputCountry {
  display: none;
}

/* Optional: Adjust padding to align the text properly after hiding the flag */
.tw-phone-input input {
  padding-left: 0.75rem; /* Adjust the padding if needed */
}

.tractFilterPopup {
  height: 70vh;
  left: 30vw;
  position: fixed;
  top: 23vh;
  width: 50vw;
  z-index: 700;
  border-radius: 15px;
  border: 1px solid #000;
  background: #f3f3f3;
  padding: 30px;
}

.soilLocationModalContent {
  height: calc(100vh - 4rem - 61px);
  overflow-y: auto;
}
