.GenericMap {
    &.expanded .left-menu-container {
      z-index: 701;
    }
  
    .left-menu-container {
      position: absolute;
      top: 0;
      background-color: white;
      margin: 10px 0 0 10px;
      border-radius: 4px;
      float: left;
      box-shadow: 0px 0px 0px 2px #0000001a;
      z-index: 2;
  
      .editing-section {
        transition: height .25s ease;
        overflow: hidden;
      }
  
      .icon-container {
        position: relative;
        width: 30px;
        height: 30px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        background-color: transparent !important;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
        color: black;
        cursor: pointer;
        font-size: inherit;
  
        &:not(:last-child) {
          border-bottom: 1px solid #dddddd;
        }
    
        .icon {

        }
    
        &:hover {
          background-color: #0000000d;
        }
  
        &:active {
          background-color: transparent !important;
          color: black;
        }
      }
    }
  
    .map-expand-overflow {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #272829c2;
      z-index: 100;
    }
  }
  