.GenericMap {
    position: relative;
    height: 100%;
  
    .map {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  
    .layer-switch {
      position: absolute;
      padding: .5rem 0;
      background-color: white;
      width: 13rem;
      margin: .75rem;
    }
  
    .mapboxgl-popup-close-button{
      font-size: 1.5rem;
      padding: .35rem .5rem .8rem .5rem;
      z-index: 10;
    }
  
    .area-container {
      position: absolute;
      width: 100%;
      justify-content: center;
      overflow: hidden;
    }
  
    .area {
      width: 7rem;
      background-color: white;
      text-align: center;
      margin: 0 auto;
      border-radius: 0 0 0.5rem 0.5rem;
      position: relative;
      bottom: 2rem;
      transition: bottom .25s ease;
  
      &.open {
        bottom: 0;
      }
    }
  
    .coordinate-section {
      position: absolute;
      bottom: 2px;
      left: 2px;
      background-color: white;
      z-index: 10;
      font-size: 12px;
      height: 27px;
      display: flex;
      align-items: center;
      width: 165px;
      justify-content: center;
    }
  
    &.expanded {
      height: 85vh;
      width: 90vw;
      position: fixed;
      left: 5vw;
      top: 10vh;
      z-index: 700;
  
      .map {
        z-index: 699;
      }
  
      .area-container {
        z-index: 701;
      }
  
      .coordinate-section {
        z-index: 800;
      }
    }
  
    .leaflet-control-layers {
      font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
    .leaflet-control-layers-group {
      margin-bottom: 0.5em;
      font-weight: bold;
      .leaflet-control-layers-group-name {
        margin-bottom: 0.2em;
        margin-left: 3px;
      }
      .leaflet-control-layers-child {
        padding-left: 10px;
        font-weight: normal;
      }
    }
    .leaflet-control-layers-label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .leaflet-control-layers-selector {
        position: relative;
        top: 1px;
        background-color: initial;
        cursor: default;
        appearance: auto;
        box-sizing: border-box;
        margin: 3px 3px 3px 4px;
        margin-top: 2px;
        padding: initial;
        border: initial;
        &:focus {
          outline: none;
          outline-offset: 0;
        }
        &:not(:checked), &:checked {
          opacity: 1;
        }
      }
    }
  }
  